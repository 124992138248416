import React from 'react';
import Helmet from '../project/Helmet';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import CartOrderInfoPageForm from '../cart/cartOrderInfo/CartOrderInfoPageForm';
import QuotationMakeOrder from './quotationMakeOrder/QuotationMakeOrder';
import NavigateButton from '../general/NavigateButton';
import withDataHOC from '../dataProvider/withDataHOC';
import {ROUTES, QUERY_PARAMS} from '../../constants/navigation';
import {GLOBAL_DATA} from '../../constants/globalData';
import {RIGHTS} from '../../constants/Rights';
import {QuotationDetail} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import { merge } from '../../lib/object';

/**
 * @fero
 */

class QuotationMakeOrderPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.QUOTATION_DETAILS]: QuotationDetail.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            params: {}
        };
    }

    componentDidMount() {
        this.setDefaults();
    }

    componentDidUpdate(prevProps) {
        const quotationDetails = this.props[GLOBAL_DATA.QUOTATION_DETAILS];
        const prevQuotationDetails = prevProps[GLOBAL_DATA.QUOTATION_DETAILS];
        const quotationId = quotationDetails != null ? quotationDetails.id : null;
        const prevQuotationId = prevQuotationDetails != null ? prevQuotationDetails.id : null;
        if(quotationId != prevQuotationId)
            this.setDefaults();
    }

    setDefaults = () => {
        const quotationDetails = this.props[GLOBAL_DATA.QUOTATION_DETAILS];
        this.setState({
            params: {
                trade: quotationDetails.trade,
                userId: quotationDetails.user != null ? quotationDetails.user.id : null,
                transportTypeId: quotationDetails.id_transport_type,
                paymentMethodId: quotationDetails.id_payment_method,
            }
        });
    }

    changeParams = (setParams) => {
        const {params} = this.state;
        this.setState({
            params: merge(params, setParams)
        });
    }

    render() {
        const {[GLOBAL_DATA.QUOTATION_DETAILS]: quotationDetails} = this.props;
        const {params} = this.state;

        const quotationId = quotationDetails.id;
        const customerDetails = quotationDetails.customer;

        if(params.trade == null)
            return null;

        return <React.Fragment>
            <Helmet
                title={`${t`Vytvorenie objednávky z cenovej ponuky`} ${quotationDetails.dealer_reference}`}
            />
            <div className="full-size-height d-flex flex-column">
                <div className="p-3 flex-item-static d-flex justify-content-between">
                    <div>
                        <NavigateButton
                            to={ROUTES.QUOTATION_DETAILS}
                            queryParams={{[QUERY_PARAMS.ID_QUOTATION]: quotationId}}
                        >
                            <Trans>Späť</Trans>
                        </NavigateButton>
                    </div>
                    <div>
                        <QuotationMakeOrder
                            quotationDetails={quotationDetails}
                            params={params}
                        />
                    </div>
                </div>
                <div className="overflow-auto">
                    <h3 className="text-center p-3">
                        <Trans>Vytvorenie objednávky z cenovej ponuky</Trans>
                        {' '}
                        {quotationDetails.dealer_reference}
                        <RightsWrapper from={RIGHTS.MARKETING}>
                            {' '}
                            <Trans>pre</Trans>
                            {' '}
                            {customerDetails.name}
                        </RightsWrapper>
                    </h3>
                    <CartOrderInfoPageForm
                        customerDetails={customerDetails}
                        params={params}
                        onChangeParams={this.changeParams}
                        tradeIsFixed={true}
                        userIsFixed={true}
                        allowProxy={true}
                    />
                </div>
            </div>
        </React.Fragment>;
    }

}

export default withDataHOC([GLOBAL_DATA.QUOTATION_DETAILS])(QuotationMakeOrderPage);